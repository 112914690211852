export const convertToISO8601Format = (
  dateString: string,
  timeString: string
): string => {
  if (!timeString || !timeString.includes(" ")) {
    throw new Error("Formato de hora inválido");
  }

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  const [time, period] = timeString.split(" ");
  if (!time || !period) {
    throw new Error("Formato de hora inválido");
  }

  let [hours, minutes] = time.split(":").map(Number);

  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error("Horas ou minutos inválidos");
  }

  if (period === "PM" && hours < 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedDate = `${year}${month}${day}T${formattedHours}${formattedMinutes}00Z`;

  return formattedDate;
};