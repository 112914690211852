import { createBrowserRouter } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ReservesDashboardPage from "../pages/Dashboard/Reserves";
import DetailPage from "../pages/Detail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  // {
  //   path: "/dashboard/reserves",
  //   element: <ReservesDashboardPage />,
  // },
  {
    path: "/yacht/:yachtName",
    element: <DetailPage />,
  },
  // {
  //   path: "/detail01",
  //   element: <Detail01Page />,
  // },
]);

export default router;
