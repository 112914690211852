export const startTimeObject = [
	{
		label: "8:30 AM",
		value: "8:30 AM",
		disabled:false
	},
	{
		label: "9:30 AM",
		value: "9:30 AM",
		disabled:false
	},
	{
		label: "10:30 AM",
		value: "10:30 AM",
		disabled:false
	},
	{
		label: "11:30 AM",
		value: "11:30 AM",
		disabled:false
	},
	{
		label: "12:30 PM",
		value: "12:30 PM",
		disabled:false
	},
	{
		label: "1:30 PM",
		value: "1:30 PM",
		disabled:false
	},
	{
		label: "2:30 PM",
		value: "2:30 PM",
		disabled:false
	},
	{
		label: "3:30 PM",
		value: "3:30 PM",
		disabled:false
	},
	{
		label: "4:30 PM",
		value: "4:30 PM",
		disabled:false
	},
	{
		label: "5:30 PM",
		value: "5:30 PM",
		disabled:false
	},
	{
		label: "6:30 PM",
		value: "6:30 PM",
		disabled:false
	},
];
