import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import "./datePicker.scss";
import PersonIcon from "../../../assets/icons/person-icon-gray.svg";
import CloseIcon from "../../../assets/icons/bx-x.svg";
import Select from "../../Selects/Select";
import InputText from "../../Inputs/InputText";
import Button from "../../Buttons/Button";
import Hr from "../../Hr";
import CircleCheckGreen from "../../../assets/icons/bxs-check-circle.svg";
import CircleCrossRed from "../../../assets/icons/bxs-x-circle.svg";
import ClockIcon from "../../../assets/icons/bx-time-five.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormYachtObj } from "../../../types/formYachtObj";
import { setupPhoneNumberInput } from "../../../utils/masks";
import { CountryCode } from "libphonenumber-js";
import SimpleSelect from "../../Selects/SimpleSelect";
import codesMock from "../../../mocks/countryCodes.json";
import { durationObject } from "../../../mocks/durationObject";
import { passengersObject } from "../../../mocks/passengersObject";
import { startTimeObject } from "../../../mocks/startTimeObject";
import { IToast } from "../../../types/toast";
import Toast from "../../Toast";
import { oneDayMore, oneMoreHour } from "./utils";
import { findSpecialCode } from "../../../api/specialCode/findSpecialCode";

interface YachtBookingFormProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  headerFormTitle: string;
  headerFormSubTitle: string;
  priceReserveFor3hr: number;
  formInformationCallback: (obj: FormYachtObj) => void;
  expDateTime: IExpDateTime[] | [];
  captainPriceFor3hrs?: number;
}

interface IExpDateTime {
  expDateStart: string;
  expDateEnd: string;
  expHourMinutesStart: string;
  expHourMinutesEnd: string;
}

const YachtBookingForm: React.FC<YachtBookingFormProps> = (props) => {
  const {
    headerFormTitle,
    headerFormSubTitle,
    formInformationCallback,
    priceReserveFor3hr,
    captainPriceFor3hrs,
    expDateTime,
    isVisible,
    setVisible,
  } = props;
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userPhoneWarning, setUserPhoneWarning] = useState(false);
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [dateExpBeginWarning, setDateExpBeginWarning] = useState(false);
  const [passengerNumber, setPassengerNumber] = useState("");
  const [passengerNumberWarning, setPassengerNumberWarning] = useState(false);
  const [duration, setDuration] = useState("");
  const [durationWarning, setDurationWarning] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [isStartTimeWarning, setIsStartTimeWarning] = useState(false);
  const [specialCode, setSpecialCode] = useState("");
  const [specialCodeWarning, setSpecialCodeWarning] = useState(false);
  const [textPriceToPay, setTextPriceToPay] = useState(
    `$ ${priceReserveFor3hr * 3},00`
  );
  const [textCaptainPriceToPay, setTextCaptainPriceToPay] = useState(
    //@ts-ignore
    `$ ${captainPriceFor3hrs * 3},00`
  );
  const [totalPriceToPay, setTotalPriceToPay] = useState(`$ 1.750,00`);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [stepToBook, setStepToBook] = useState(0);
  const [widescreen, setWidescreen] = useState(window.innerWidth);
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState("" as string);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [valueInputDate, setValueInputDate] = useState("");
  const [dateTimesOccupied, setDateTimeOccupied] =
    useState<{ dateStart: string; dateEnd: string; timesArr: string[] }[]>();
  const [ableTimes, setAbleTimes] = useState<
    { label: string; value: string; disabled: boolean }[] | []
  >([]);
  const [disabledDates, setDisabledDates] = useState<Date[] | []>([]);
  const [highlightDates, setHighlightDates] = useState<Date[] | []>([]);
  const [toastConfig, setToastConfig] = useState<IToast>({
    description: "",
    title: "",
    type: "error",
  });
  const [showToast, setShowToast] = useState(false);
  const [ableTimesResetText, setAbleTimesResetText] = useState("-");
  const [specialCodeDetails, setSpecialCodeDetails] = useState("");
  const [valueCode, setValueCode] = useState("");
  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    let hourTomorrow = tomorrow.getHours();
    let minutesTomorrow = tomorrow.getMinutes();
    let period = hourTomorrow >= 12 ? "PM" : "AM";

    hourTomorrow = hourTomorrow % 12 || 12;

    if (minutesTomorrow > 30) {
      hourTomorrow += 1;
    }
    minutesTomorrow = 30;

    let timeTomorrowFormatted = `${hourTomorrow}:30 ${period}`;
    let dateTomorrowFormatted = `${tomorrow.getFullYear()}-${String(
      tomorrow.getMonth() + 1
    ).padStart(2, "0")}-${String(tomorrow.getDate()).padStart(2, "0")}`;

    console.log(timeTomorrowFormatted);
    console.log(dateTomorrowFormatted);

    console.log(hourTomorrow);

    console.log(timeTomorrowFormatted);
    let disabledTimesUntilTomorrow = {
      dateStart: dateTomorrowFormatted,
      dateEnd: dateTomorrowFormatted,
      timesArr: oneMoreHour("8:30 AM", timeTomorrowFormatted),
    };

    let reservedDateTimes = expDateTime?.map((time) => ({
      dateStart: time.expDateStart,
      dateEnd: time.expDateEnd,
      timesArr: oneMoreHour(time.expHourMinutesStart, time.expHourMinutesEnd),
    }));

    reservedDateTimes.push(disabledTimesUntilTomorrow);

    reservedDateTimes.forEach((reserve) => {
      if (reserve.dateStart !== reserve.dateEnd) {
        reservedDateTimes = reservedDateTimes.filter(
          (r) => r.dateStart !== reserve.dateStart
        );

        let start = reserve.dateStart;
        let newReservations = [];

        while (start !== reserve.dateEnd) {
          newReservations.push({
            dateEnd: start,
            dateStart: start,
            timesArr: startTimeObject.map((time) => time.value),
          });

          start = oneDayMore(start.replaceAll("-", "/")).replaceAll("/", "-");
        }

        newReservations.push({
          dateEnd: reserve.dateEnd,
          dateStart: reserve.dateEnd,
          timesArr: startTimeObject.map((time) => time.value),
        });

        reservedDateTimes.push(...newReservations);
      }
    });

    const fullyBookedDates = reservedDateTimes.filter(
      (date) => date.timesArr.length >= startTimeObject.length
    );

    setDisabledDates(
      fullyBookedDates.map((date) => new Date(`${date.dateStart}T00:00:00`))
    );

    setHighlightDates(
      reservedDateTimes
        .filter((dateTime) => dateTime.timesArr.length < startTimeObject.length)
        .map((date) => new Date(`${date.dateStart}T00:00:00`))
    );

    setDateTimeOccupied(reservedDateTimes);
  }, [expDateTime]);

  useEffect(() => {
    const selectedDate = startDate?.toISOString().split("T")[0];

    if (dateTimesOccupied) {
      const hours = dateTimesOccupied?.filter(
        (date) => date.dateStart === selectedDate
      )[0];

      const times = startTimeObject.map((time) => ({
        ...time,
        disabled: hours?.timesArr?.includes(time.value) ? true : false,
      }));

      setAbleTimes(times);
    }
  }, [dateTimesOccupied, startDate]);

  useEffect(() => {
    //@ts-ignore
    const parsePrice = (priceString) => {
      return parseFloat(priceString.replace(/[^0-9,]/g, "").replace(",", "."));
    };
    //@ts-ignore
    const formatPrice = (price) => {
      return `$ ${price
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(".", ",")}`;
    };
    const priceToPay = parsePrice(textPriceToPay);
    const captainPriceToPay = parsePrice(textCaptainPriceToPay);
    const totalPrice = priceToPay + captainPriceToPay;
    const formattedTotalPrice = formatPrice(totalPrice);

    setTotalPriceToPay(formattedTotalPrice);
  }, [textPriceToPay, textCaptainPriceToPay]);

  useEffect(() => {
    const handleResize = () => {
      setWidescreen(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeModalHandler = () => {
    setUserName("");
    setUserEmail("");
    setPassengerNumber("");
    setDuration("");
    setStartTime("");
    setVisible(false);
    setUserPhone("");
    setEndDate(null);
    setStartDate(null);
    setStepToBook(0);
  };

  const changeDurationHandler = (a: string) => {
    let time = Number(a.substring(0, 1));
    let price: number;

    if (time <= 3) {
      price = priceReserveFor3hr * time;
    } else {
      let additionalHours = time - 3;
      let discount = 0;

      if (additionalHours >= 1 && additionalHours < 3) {
        discount = 0.05;
      } else if (additionalHours >= 3 && additionalHours < 5) {
        discount = 0.08;
      } else if (additionalHours >= 5) {
        discount = 0.1;
      }

      let basePrice = priceReserveFor3hr * 3;
      let discountedPrice =
        priceReserveFor3hr * (1 - discount) * additionalHours;
      price = basePrice + discountedPrice;
    }
    //@ts-ignore
    let captainPrice = captainPriceFor3hrs * time;

    setTextCaptainPriceToPay(`$ ${captainPrice},00`);
    setTextPriceToPay(`$ ${price},00`);
    setAbleTimesResetText("-");

    setDuration(a);
  };

  const calculateTotalValue = (cap: string, boat: string, disc?: string) => {
    const captainValue = parseFloat(
      cap.replace(/[^\d.-]/g, "").replace(",", ".") || "0"
    );
    const bookingValue = parseFloat(
      boat.replace(/[^\d.-]/g, "").replace(",", ".") || "0"
    );

    let finalBoatValue = bookingValue;

    if (disc) {
      const discountValue = parseFloat(disc.replace(/[^\d.-]/g, "") || "0");
      if (disc.includes("%")) {
        finalBoatValue -= (finalBoatValue * discountValue) / 100;
      } else {
        finalBoatValue -= discountValue;
      }
    }

    const totalValue = finalBoatValue + captainValue;

    return `$ ${
      totalValue.toString().slice(0, -2) + "," + totalValue.toString().slice(-2)
    }`;
  };

  const toSecondFormBookingHandler = () => {
    if (!valueInputDate) {
      setDateExpBeginWarning(true);
      setTimeout(() => setDateExpBeginWarning(false), 3000);
      return;
    }

    if (!valueInputDate.includes("until") && !duration) {
      setDurationWarning(true);

      setTimeout(() => setDurationWarning(false), 3000);
      return;
    }

    if (!passengerNumber) {
      setPassengerNumberWarning(true);
      setTimeout(() => setPassengerNumberWarning(false), 3000);
      return;
    }

    if (!valueInputDate) {
      setDateExpBeginWarning(true);
      setTimeout(() => setDateExpBeginWarning(false), 3000);
      return;
    }

    if (!startTime) {
      setIsStartTimeWarning(true);
      setTimeout(() => setIsStartTimeWarning(false), 3000);
      return;
    }
    setStepToBook((prev) => prev + 1);
  };

  const toConfirmDataHandler = () => {
    if (!userName) {
      setUserNameWarning(true);
      setTimeout(() => setUserNameWarning(false), 3000);
      return;
    }

    if (!userEmail) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (userEmail.indexOf("@") === -1 || userEmail.indexOf(".com") === -1) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (!userPhone) {
      setUserPhoneWarning(true);
      setTimeout(() => setUserPhoneWarning(false), 3000);
      return;
    }

    if (valueInputDate?.split(" until ")[1]) {
      setDuration("");
    }

    setStepToBook((prev) => prev + 1);
  };

  const confirmBookingDataHandler = () => {
    const totalPrice = calculateTotalValue(
      textCaptainPriceToPay,
      textPriceToPay,
      valueCode
    );

    const formYachtObj: FormYachtObj = {
      userName,
      userEmail,
      dateExpBegin: valueInputDate?.split(" until ")[0],
      dateExpEnd: valueInputDate?.split(" until ")[1],
      passengerNumber,
      duration,
      captainValue: textCaptainPriceToPay,
      startTime,
      bookingValue: textPriceToPay,
      specialCode: specialCodeDetails,
      userPhone: `${countryCode}${userPhone.replace(/\D/g, "")}`,
      discount: valueCode,
      totalValue: totalPrice,
    };
    setStepToBook((prev) => prev + 1);
    formInformationCallback(formYachtObj);
  };

  const countryCodeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPhoneCode(e.target.value);
  };

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );

      setUserPhone(formattedNumber);

      setCountryCode(codeCountry.countryCode);
    } else {
      setUserPhone(e);
      setUserPhone(e.replace(/\D/g, ""));
    }
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleApplyClick = () => {
    let startedDateFormatted;
    let endDateFormatted;

    const disabledDatesArray = disabledDates?.map((date) =>
      new Date(date).toISOString().split("T")[0].replaceAll("-", "/")
    );

    if (startDate) {
      startedDateFormatted = new Date(startDate)
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "/");
    }

    if (endDate) {
      endDateFormatted = new Date(endDate)
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "/");
    }

    let date: string | undefined = startedDateFormatted;

    if (!endDate || startedDateFormatted === endDateFormatted) {
      date = startedDateFormatted;
    } else {
      let start = startedDateFormatted;
      let end = endDateFormatted;

      let count = 0;
      while (start && start !== end && count <= 50) {
        count += 1;

        const nextDay = oneDayMore(start);
        const timesOccupied = dateTimesOccupied?.map((date) =>
          date.dateStart.replaceAll("-", "/")
        );

        if (disabledDatesArray?.includes(nextDay)) {
          showErrorToast("Some of the selected dates are filled.");
          resetDates();
          return;
        }

        if (timesOccupied?.includes(nextDay)) {
          showErrorToast("Some of the selected dates contain filled times.");
          resetDates();
          return;
        }

        start = nextDay;
      }

      setDuration("");

      date = `${startedDateFormatted} until ${endDateFormatted}`;
    }

    setValueInputDate(date!);

    if (startDate && !endDate) {
      setEndDate(startDate);
    }

    setCalendarOpen(false);
  };

  const showErrorToast = (description: string) => {
    setShowToast(true);
    setToastConfig({
      description,
      title: "Oops!",
      type: "info",
    });
    setTimeout(() => {
      setShowToast(false);
    }, 4000);
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const durationReviewer = (startTime: string) => {
    const availableTimes = ableTimes
      .filter((timeSlot) => timeSlot.disabled !== false)
      .map((timeSlot) => timeSlot.value);

    let currentHour = Number(startTime.split(":")[0]);
    let currentMinutes = startTime.split(":")[1].split(" ")[0];
    let currentPeriod = startTime.split(" ")[1];
    const durationInHours = Number(duration.split("hrs")[0]);

    if (currentPeriod === "PM" && currentHour !== 12) {
      currentHour += 12;
    } else if (currentPeriod === "AM" && currentHour === 12) {
      currentHour = 0;
    }

    const formatTime = (hour: number, minutes: string) => {
      const period = hour >= 12 ? "PM" : "AM";
      let formattedHour = hour > 12 ? hour - 12 : hour;
      if (formattedHour === 0) formattedHour = 12;
      return `${formattedHour}:${minutes} ${period}`;
    };

    for (let i = 0; i < durationInHours; i++) {
      const formattedTime = formatTime(currentHour, currentMinutes);

      if (availableTimes.includes(formattedTime)) {
        setToastConfig({
          type: "info",
          title: "Oops",
          description: `You cannot book this time because it is either already
						 taken or falls between reserved times. Please choose another
						 available slot.`,
        });
        setAbleTimesResetText("-");
        setStartTime("");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 6000);

        return;
      }

      currentHour += 1;

      if (currentHour >= 24) {
        currentHour -= 24;
      }
    }

    setStartTime(startTime);
  };

  const findSpecialCodeHandler = () => {
    setIsLoading(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      setButtonSubmitDisabled(true);
      const response = await findSpecialCode(specialCode.toUpperCase());

      if (response.status === 200) {
        setToastConfig({
          description: "Special code applied successfully",
          title: response.res.details,
          type: "info",
        });
        setShowToast(true);
        setSpecialCodeDetails(
          `${response.res.code_digits} ${response.res.details}`
        );

        setValueCode(response.res.value_code);

        setTimeout(() => {
          setSpecialCodeWarning(false);
          setShowToast(false);
        }, 5000);
      } else {
        setSpecialCodeWarning(true);
        setToastConfig({
          description: "Special code not found",
          title: "Oops...",
          type: "info",
        });
        setShowToast(true);

        setTimeout(() => {
          setSpecialCodeWarning(false);
          setShowToast(false);
        }, 4000);
        setSpecialCodeDetails("");
      }
      setButtonSubmitDisabled(false);
    }, 1000);

    setIsLoading(false);
  };

  return (
    <>
      <div
        id="yacht-booking-form"
        className={`${isVisible ? "show-form" : ""} ${
          widescreen >= 768 ? "widescreen" : ""
        }`}
      >
        {stepToBook === 0 || stepToBook === 1 ? (
          <div className="header-form">
            {widescreen < 768 ? (
              <img
                src={CloseIcon}
                alt="close icon"
                onClick={() => closeModalHandler()}
              />
            ) : null}
            <h3 className="disclaimer">{headerFormTitle}</h3>
            <p>{headerFormSubTitle}</p>
            <Hr hasHorizontalMargin={false} />
          </div>
        ) : null}
        <div className="body-form">
          {stepToBook === 0 ? (
            <>
              <div className="date-picker-container">
                <label>Date</label>
                <InputText
                  onChangeInput={() => {}}
                  onClick={() => setCalendarOpen(true)}
                  value={valueInputDate}
                  readOnly
                  placeholder="Pick a Date"
                  isWarning={dateExpBeginWarning}
                  inputStyle="input-date-picker"
                />
                {calendarOpen && (
                  <>
                    <img
                      src={CloseIcon}
                      alt="X icon"
                      onClick={() => setCalendarOpen(false)}
                    />
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      startDate={startDate as Date}
                      endDate={endDate as Date}
                      selectsRange
                      inline
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      }
                      selectsDisabledDaysInRange={false}
                      highlightDates={highlightDates}
                      excludeDates={disabledDates}
                      showPreviousMonths={false}
                      monthsShown={widescreen >= 768 ? 2 : 12}
                    >
                      <Button buttonStyle="primary" onClick={handleApplyClick}>
                        APPLY
                      </Button>
                    </DatePicker>
                  </>
                )}
              </div>

              <div className="grid-column">
                <Select
                  options={durationObject}
                  labelSelect="Duration"
                  onChange={changeDurationHandler}
                  iconLeft={ClockIcon}
                  isWarning={durationWarning}
                  disabled={valueInputDate?.includes("until")}
                  defaultValueText={duration}
                />
                <Select
                  options={passengersObject}
                  labelSelect="Passengers"
                  iconLeft={PersonIcon}
                  onChange={setPassengerNumber}
                  isWarning={passengerNumberWarning}
                  defaultValueText={passengerNumber}
                />
              </div>

              <Select
                options={ableTimes}
                labelSelect="Start Time"
                onChange={durationReviewer}
                iconLeft={ClockIcon}
                defaultValueText={startTime}
                disabled={!ableTimes.length}
                isWarning={isStartTimeWarning}
              />

              <div className="form-footer">
                <div className="footer-form-container">
                  {valueInputDate?.includes("until") ? (
                    <h5>You will receive the quote via email shortly.</h5>
                  ) : (
                    <table>
                      <tbody>
                        <tr>
                          <th>BOAT</th>
                          <td>{textPriceToPay}</td>
                        </tr>
                        <tr>
                          <th>CAPTAIN</th>
                          <td>{textCaptainPriceToPay}</td>
                        </tr>

                        <tr>
                          <th>TOTAL</th>
                          <td>{totalPriceToPay}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {widescreen <= 768 ? (
                    <Button
                      onClick={toSecondFormBookingHandler}
                      buttonStyle="secondary"
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button
                      onClick={toSecondFormBookingHandler}
                      buttonStyle="primary"
                    >
                      Instant Book
                    </Button>
                  )}
                </div>
              </div>
            </>
          ) : stepToBook === 1 ? (
            <>
              <div className="body-form">
                <InputText
                  type="text"
                  value={userName}
                  onChangeInput={setUserName}
                  label="Name *"
                  isWarning={userNameWarning}
                />
                <InputText
                  type="email"
                  value={userEmail}
                  onChangeInput={setUserEmail}
                  label="Email *"
                  isWarning={userEmailWarning}
                />
              </div>
              <p className="label-input-text">Phone *</p>
              <div className="grid-column-phone">
                <SimpleSelect
                  options={codesMock.map((code) => {
                    return {
                      label: `${code.flag} ${code.code} ${code.countryCode}`,
                      value: JSON.stringify(code),
                    };
                  })}
                  onChange={countryCodeHandler}
                  value={phoneCode}
                  defaultValueText={"Select"}
                />
                <InputText
                  value={userPhone}
                  onChangeInput={inputPhoneHandler}
                  placeholder="Phone number"
                  isWarning={userPhoneWarning}
                />
              </div>

              <InputText
                onKeyUp={findSpecialCodeHandler}
                type="text"
                value={specialCode.toUpperCase().trim()}
                onChangeInput={setSpecialCode}
                label="Special Code"
                isWarning={specialCodeWarning}
              />
              {widescreen <= 768 ? (
                <Button
                  onClick={toConfirmDataHandler}
                  buttonStyle="secondary"
                  disabled={buttonSubmitDisabled || isLoading}
                >
                  Instant Book
                </Button>
              ) : (
                <Button
                  onClick={toConfirmDataHandler}
                  buttonStyle="secondary"
                  disabled={buttonSubmitDisabled || isLoading}
                >
                  Submit Booking
                </Button>
              )}
            </>
          ) : stepToBook === 2 ? (
            <>
              <div className="body-form reservation-data-list">
                <h2 className="reservation-data__title">
                  Confirm your reservation
                </h2>
                <p className="reservation-data__sub-title">
                  Please confirm your reservation details below
                </p>

                <hr className="separator" />

                <section className="reservation-data">
                  <section className="reservation-data-sup">
                    <div className="reservation-data__values">
                      <div className="reservation-data__key-value">
                        <p className="data-key">Date</p>
                        <p className="data-value">{valueInputDate}</p>
                      </div>
                      {!valueInputDate.includes(" until ") && (
                        <div className="reservation-data__key-value">
                          <p className="data-key">Duration</p>
                          <p className="data-value">{duration}</p>
                        </div>
                      )}
                    </div>
                    <div className="reservation-data__values">
                      <div className="reservation-data__key-value">
                        <p className="data-key">Start Time</p>
                        <p className="data-value">{startTime}</p>
                      </div>
                      <div className="reservation-data__key-value">
                        <p className="data-key">Passengers</p>
                        <p className="data-value">{passengerNumber}</p>
                      </div>
                    </div>
                  </section>
                  <hr className="separator" />
                  <section className="reservation-data-sub">
                    <div className="reservation-data__values">
                      <div className="reservation-data__key-value">
                        <p className="data-key">Name</p>
                        <p className="data-value">{userName}</p>
                      </div>
                      <div className="reservation-data__key-value">
                        <p className="data-key">Phone</p>
                        <p className="data-value">{userPhone}</p>
                      </div>
                    </div>
                    <div className="reservation-data__values">
                      <div className="reservation-data__key-value">
                        <p className="data-key">Email</p>
                        <p className="data-value">{userEmail}</p>
                      </div>
                      <div className="reservation-data__key-value">
                        <p className="data-key">Special Code</p>
                        <p className="data-value">
                          {specialCode ? specialCode?.toUpperCase() : " - "}
                        </p>
                      </div>
                    </div>
                  </section>
                  <hr className="separator" />
                  <section className="reservation-data__footer">
                    <p className="footer__total">
                      Total{" "}
                      <span>
                        {calculateTotalValue(
                          textCaptainPriceToPay,
                          textPriceToPay,
                          valueCode
                        )}
                      </span>
                    </p>
                    <Button
                      onClick={confirmBookingDataHandler}
                      buttonStyle="primary"
                    >
                      Confirm & Book
                    </Button>
                    <Button
                      onClick={() => setStepToBook((prev) => prev - 2)}
                      buttonStyle="tertiary"
                    >
                      Edit
                    </Button>
                    <hr className="separator" />
                    <p className="text-explanatory">You won't be charged yet</p>
                  </section>
                </section>
              </div>
            </>
          ) : stepToBook === 3 ? (
            <>
              <div className="body-form">
                <div className="modal-form-finish-message">
                  <img src={CircleCheckGreen} alt="circle check" />
                  <h1 id="thank-you-modal" className="message-title">
                    Booking successful!
                  </h1>
                  <p className="message-subtitle">
                    We will contact you shortly with details on how to complete
                    your reservation.
                  </p>
                </div>
                <Button onClick={closeModalHandler} buttonStyle="secondary">
                  Close
                </Button>
              </div>
            </>
          ) : stepToBook === 4 ? (
            <>
              <div className="body-form">
                <div className="modal-form-finish-message">
                  <img src={CircleCrossRed} alt="circle error" />
                  <h1 className="message-title">Oops!</h1>
                  <p className="message-subtitle">
                    Something went wrong, please try again.
                  </p>
                </div>
                <Button onClick={closeModalHandler} buttonStyle="primary">
                  Try Again
                </Button>
                <Button onClick={closeModalHandler} buttonStyle="secondary">
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {stepToBook === 0 || stepToBook === 1 ? (
          <>
            <div className="footer-cancellation">
              <img src={CircleCheckGreen} alt="circle check" />
              <h5 className="no-margin">Free Cancelation</h5>
              <p>Full refund 24hrs before booking date</p>
            </div>
            <div className="footer-last-text">
              <Hr />
              <p className="disclaimer">
                You won't be charged yet. Please note that reservations must be
                made at least 24 hours in advance.
              </p>
            </div>
          </>
        ) : null}
      </div>

      <Toast
        description={toastConfig.description}
        isVisible={showToast}
        setIsVisible={setShowToast}
        title={toastConfig.title}
        type={toastConfig.type}
      />
    </>
  );
};

export default YachtBookingForm;
