import vehicle2 from "../assets/images/nx400/yacht-charter-nx-40.png";
import vehicle4 from "../assets/images/vehicle4.png";
// import nx08 from "../assets/images/nx400/yacht-charter-nx-40-01.png";
// import nx09 from "../assets/images/nx400/yacht-charter-nx-40-02.png";
// import nx10 from "../assets/images/nx400/yacht-charter-nx-40-03.png";

import nx01 from "../assets/images/nx400/yacht_charter_miami-nx_40-01.png";
import nx02 from "../assets/images/nx400/yacht_charter_miami-nx_40-02.png";
import nx03 from "../assets/images/nx400/yacht_charter_miami-nx_40-03.png";
import nx04 from "../assets/images/nx400/yacht_charter_miami-nx_40-04.png";
import nx05 from "../assets/images/nx400/yacht_charter_miami-nx_40-05.png";
import nx06 from "../assets/images/nx400/yacht_charter_miami-nx_40-06.png";
import nx07 from "../assets/images/nx400/yacht_charter_miami-nx_40-07.png";
import nx08 from "../assets/images/nx400/yacht_charter_miami-nx_40-08.png";
import nx09 from "../assets/images/nx400/yacht_charter_miami-nx_40-09.png";
import nxVideo from "../assets/videos/video-destaque-Nx400ht.mp4";

//Sunseeker
import sunseeker01 from "../assets/images/sunseeker/sunseeker01.jpeg";
import sunseeker02 from "../assets/images/sunseeker/sunseeker02.jpeg";
import sunseeker03 from "../assets/images/sunseeker/sunseeker03.jpeg";
import sunseeker04 from "../assets/images/sunseeker/sunseeker04.png";
import sunseeker05 from "../assets/images/sunseeker/sunseeker05.jpeg";
import sunseeker06 from "../assets/images/sunseeker/sunseeker06.jpeg";
import sunseeker07 from "../assets/images/sunseeker/sunseeker07.jpeg";

export const boats = [
  {
    name: "NX Horizon 40'",
    specification: "Yacht 2022",
    texts: {
      title: "NX Horizon 40’<br>Yacht 2022",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning NX Horizon 40' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    prices: {
      card: "1,200",
      pricePerHour: 400,
    },
    captainPrices: {
      captainPricePerHour: 50,
    },
    routeName: "2022-nx-horizon-40",
    vehicleImg: vehicle2,
    showComingSoon: false,
    images: [
      { id: "0", img: nxVideo, type:"video" },
      { id: "1", img: nx01, type:"photo" },
      { id: "2", img: nx02, type:"photo" },
      { id: "3", img: nx03, type:"photo" },
      { id: "4", img: nx04, type:"photo" },
      { id: "5", img: nx05, type:"photo" },
      { id: "6", img: nx06, type:"photo" },
      { id: "7", img: nx07, type:"photo" },
      { id: "8", img: nx08, type:"photo" },
      { id: "9", img: nx09, type:"photo" },
    ],
  },
  {
    name: "Sunseeker 80'",
    specification: "Sport Yacht 2017",
    texts: {
      title: "Sunseeker 80’ Sport Yacht 2017",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the
								stunning Sunseeker 80’ Sport Yacht 2017, exclusively offered
								by Yacht Charter Miami. Our bespoke yacht charters promise an
								unparalleled luxury experience.`,
    },
    prices: {
      card: "1,200",
      pricePerHour: 400,
    },
    captainPrices: {
      captainPricePerHour: 50,
    },
    routeName: "2017-sunseeker-80-yacht",

    vehicleImg: vehicle4,
    showComingSoon: false,
    images: [
      { id: "1", img: sunseeker01 },
      { id: "2", img: sunseeker02 },
      { id: "3", img: sunseeker03 },
      { id: "4", img: sunseeker04 },
      { id: "5", img: sunseeker05 },
      { id: "6", img: sunseeker06 },
      { id: "7", img: sunseeker07 },
    ],
  },
];
