import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
  Keyboard,
} from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss/effect-fade";
import SmokyContainer from "../../Wrappers/SmokyContainer";
import TitleParagraph from "../../Texts/TitleParagraph";
import Button from "../../Buttons/Button";

interface CarouselDefaultProps {
  smokyImage?: boolean;
  spaceBetween?: number;
  imagesCarousel: {
    id: string;
    img: string;
    type?: "video" | "photo";
  }[];
  hasBorderRadius?: boolean;
  //   titleParagraph?: { title: string; paragraph: string };
  activeImageId?: string;
  infoText?: {
    title: string;
    subTitle: string;
    buttonText: string;
    buttonClick: (a: any) => void;
  };
}

const CarouselDefault: React.FC<CarouselDefaultProps> = (props) => {
  const {
    imagesCarousel,
    smokyImage,
    spaceBetween = 30,
    hasBorderRadius = true,
    infoText,
    activeImageId,
  } = props;

  const [swiper, setSwiper] = useState<any>(null);
  const swiperRef = useRef<any>(null);

  const [activeIndex, setActiveIndex] = useState<number>(
    activeImageId
      ? imagesCarousel.findIndex((img) => img.id === activeImageId)
      : 0
  );

  useEffect(() => {
    if (activeImageId) {
      const newIndex = imagesCarousel.findIndex(
        (img) => img.id === activeImageId
      );
      if (newIndex !== -1) {
        setActiveIndex(newIndex);
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideTo(newIndex);
        }
      }
    }
  }, [activeImageId, imagesCarousel, swiperRef]);

  const handleSwiperInit = (swiper: any) => {
    setSwiper(swiper);
  };

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={spaceBetween}
      centeredSlides={true}
      // autoplay={{
      //   delay: 6000,
      //   // disableOnInteraction: false,
      // }}
      pagination={{
        clickable: true,
      }}
      effect="fade"
      speed={2000}
      navigation={true}
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
      modules={[Autoplay, Pagination, Navigation, EffectFade, Keyboard]}
      id="carousel-default"
      className={`${hasBorderRadius ? "border-radius " : ""}`}
      initialSlide={activeIndex}
      onSwiper={handleSwiperInit}
    >
      {imagesCarousel.map((img) => (
        <SwiperSlide key={img.id} className="carousel-container">
          {smokyImage ? (
            <SmokyContainer image={img.img} alt="img carousel" />
          ) : (
            <>
              {img.type && img.type === "video" ? (
                <video
                  className="carrousel-file"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={img.img} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img className="carrousel-file" src={img.img} alt="yacht" />
              )}
            </>
          )}
        </SwiperSlide>
      ))}
      {infoText && (
        <div className="yacht-info-text">
          <TitleParagraph
            title={infoText.title}
            paragraph={infoText.subTitle}
            titleColor="white"
            paragraphColor="white"
            isBannerTitle
          />
          <Button buttonStyle="tertiary" onClick={infoText.buttonClick}>
            {infoText.buttonText}
          </Button>
        </div>
      )}
    </Swiper>
  );
};

export default CarouselDefault;
