import React from "react";
import "./styles.scss";
import Title from "../../Texts/Title";
import SpecsPill from "../../Pills/SpecsPill";
import Hr from "../../Hr";
import ListSpec from "../../ListItem/ListSpec";

const boatSpecs = [
  {
    specTitle: "Length",
    subSpec: "24.38 m",
    mainSpec: "80'",
  },
  {
    specTitle: "Beam",
    subSpec: "5.97 m",
    mainSpec: "19'6",
  },
  {
    specTitle: "Draft",
    subSpec: "1.83 m",
    mainSpec: "6'0",
  },
  {
    specTitle: "Cruising Speed",
    subSpec: "approx.",
    mainSpec: "24 Knots",
  },
  {
    specTitle: "Year Built",
    subSpec: "",
    mainSpec: "2017",
  },
  {
    specTitle: "Builder",
    subSpec: "",
    mainSpec: "Sunseeker",
  },
  {
    specTitle: "Model",
    subSpec: "",
    mainSpec: "80’ Sport Yacht",
  },
  {
    specTitle: "Class",
    subSpec: "(Sport)",
    mainSpec: "Yacht",
  },
];

const SpecificationsCard01: React.FC = () => {
  return (
    <div id="specifications-wrapper">
      <Title isTitleDetails>Specifications</Title>
      <div className="specs-highlight">
        <SpecsPill fontFamilyTitle="futura" title={"Passengers"} spec={"12"} />
        <SpecsPill fontFamilyTitle="futura" title={"Crew"} spec={"3-4"} />
        <SpecsPill fontFamilyTitle="futura" title={"Cabins"} spec={"4"} />
      </div>
      {boatSpecs.map((spec, index) => (
        <React.Fragment key={index}>
          <ListSpec
            specTitle={spec.specTitle}
            subSpec={spec.subSpec}
            mainSpec={spec.mainSpec}
          />
          {index < boatSpecs.length - 1 && <Hr />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SpecificationsCard01;
