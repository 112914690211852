import axios from "axios";
import { apiServices } from "../apiServices";

export type SendEmailReserveInfo = {
  name: string;
  boat: string;
  hour: string | undefined;
  number_passenger: number;
  start_date: string;
  end_date: string | undefined;
  google_calendar_url: string;
  to: string;
  bookingValue: string;
  captainValue: string;
  startTime: string;
  discount: string | undefined;
  totalValue: string;
  linkParameter: string[] | undefined;
  clientPhone: string;
  clientEmail: string;
  specialCode: string | undefined;
};

export const sendEmailReserveInfo = async (data: SendEmailReserveInfo) => {
  const req = await axios({
    method: "post",
    url: `${apiServices()}/send-booking-info-email`,
    data,
  });

  return { status: req.status, res: req.data };
};
