import axios from "axios";
import { apiServices } from "../apiServices";

export const getReservesFromCalendar = async () => {
  const req = await axios({
    method: "get",
    url: `${apiServices()}/list-calendar-events`,
  });

  return { status: req.status, res: req.data };
};
