import React, { ChangeEvent } from "react";
import "./styles.scss";
import VMasker from "vanilla-masker";

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputStyle?: string;
  mask?: string;
  value: string;
  onChangeInput: (a: string) => void;
  label?: string;
  isWarning?: boolean;
  className?: string;
}

const InputText: React.FC<InputTextProps> = (props) => {
  const {
    inputStyle,
    value,
    mask,
    onChangeInput,
    label,
    isWarning,
    className,
    ...rest
  } = props;

  const handlerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (mask) {
      onChangeInput(VMasker.toPattern(inputValue, mask));
    } else {
      onChangeInput(inputValue);
    }
  };

  return (
    <div id="input-text-default" className={className ? className : ""}>
      {label && <p className="label-input-text">{label}</p>}

      <input
        className={`${inputStyle ? inputStyle : ""} ${
          isWarning ? "isWarning" : ""
        }   `}
        value={value}
        onChange={handlerInput}
        {...rest}
      />
    </div>
  );
};

export default InputText;
