import React, { ReactNode } from "react";
import "./styles.scss";

interface TitleProps {
  children: string;
  color?: "black" | "white";
  isTitleDetails?: boolean;
  isBannerTitle?: boolean;
}

const Title: React.FC<TitleProps> = (props) => {
  const { children, color = "black", isTitleDetails, isBannerTitle } = props;
  return (
    <h1
      id="title-component"
      className={`title-color-${color} ${
        isTitleDetails ? "title-detail" : isBannerTitle ? "banner-title" : ""
      }`}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export default Title;
