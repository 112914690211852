import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";

import yachtCharterLogoWhite from "../../../assets/logos/yacht_charter_logo.svg";
import yachtCharterLogoBlue from "../../../assets/logos/yacht_charter_logo_blue.svg";
import ListYachtForm from "../../Forms/ListYachtForm";
import { FormYachtObj } from "../../../types/formYachtObj";
import emailjs from "emailjs-com";
import { IBooking } from "../../../types/reserve";
import ContactForm from "../../Forms/ContactForm";
import { boats } from "../../../mocks/boats";
import { sendEmailListYacht } from "../../../api/listYacht/sendEmailListYacht";
import { sendEmailListYachtInfo } from "../../../api/listYacht/sendEmailListYachtInfo";

const NavBarTransparent: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState<"listYacht" | "contact" | null>(
    null
  );

  const navigate = useNavigate();
  const location = useLocation();
  //   const handleOpenApp = () => {
  //     navigate("/app");
  //   };

  const handleTriggerClick = () => {
    const navWrapper = document.querySelector("#nav-bar-landing-page");
    if (navWrapper) {
      navWrapper.classList.toggle("open");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const formListSubmit = async (obj: {
    userName: string;
    userEmail: string;
    location: string;
    yearYacht: string;
    make: string;
    modelYacht: string;
    userPhone: string;
  }) => {
    const listParams = {
      user_name: obj.userName,
      yacht_location: obj.location,
      yacht_year: obj.yearYacht,
      yacht_make: obj.make,
      yacht_model: obj.modelYacht,
      to: obj.userEmail,
    };

    let viaLink = localStorage.getItem("urlParams");

    const listParamsInfo = {
      user_name: obj.userName,
      yacht_location: obj.location,
      yacht_year: obj.yearYacht,
      yacht_make: obj.make,
      yacht_model: obj.modelYacht,
      to: obj.userEmail,
      user_phone: obj.userPhone,
      user_email: obj.userEmail,
      via_link: viaLink
        ? Object.values(JSON.parse(viaLink)).map((value) => value + ", ")
        : undefined,
    };

    const responseList = await sendEmailListYacht(listParams);
    const responseListInfo = await sendEmailListYachtInfo(listParamsInfo);

    if (responseList.status === 200 && responseListInfo.status === 200) {
      localStorage.clear();
    }
  };

  const contactSubmit = () => {};

  const openModalHandler = (type: "listYacht" | "contact") => {
    window.scrollTo(0, 0);
    setModalType(type);
    setIsOpenModal(true);
  };

  const handleBookNowClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const targetRoute = `/yacht/${boats[0].routeName}`;

    if (location.pathname === targetRoute) {
      const element = document.getElementById("yacht-booking-form");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(targetRoute);
    }
  };

  return (
    <>
      {!isOpenModal && (
        <div id="nav-bar-landing-page" className={isScrolled ? "scrolled" : ""}>
          <div className="logo-container" onClick={() => navigate("/")}>
            <img
              src={isScrolled ? yachtCharterLogoBlue : yachtCharterLogoWhite}
              alt="Yacht Charter Miami Logo"
            />
          </div>
          <div
            className={
              isScrolled
                ? "button-container-nav-bar scrolled"
                : "button-container-nav-bar"
            }
          >
            <ul className="nav">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a
                  href={`/yacht/${boats[0].routeName}`}
                  onClick={handleBookNowClick}
                >
                  Instant Book
                </a>
              </li>
              <li>
                <p
                  className="menu-item"
                  onClick={() => openModalHandler("listYacht")}
                >
                  List With Us
                </p>
              </li>
              <li>
                <p
                  className="menu-item"
                  onClick={() => openModalHandler("contact")}
                >
                  Contact Us
                </p>
              </li>
            </ul>
            {/* <Button
			buttonStyle="primary"
			children={<TranslatableText textKey="1/navigationButton" />}
			onClick={handleOpenApp}
		/> */}
          </div>
          <div
            className={isScrolled ? "trigger scrolled" : "trigger"}
            onClick={handleTriggerClick}
          ></div>
        </div>
      )}

      {isOpenModal && modalType === "listYacht" && (
        <ListYachtForm
          formInformationCallback={formListSubmit}
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
        />
      )}
      {isOpenModal && modalType === "contact" && (
        <ContactForm
          formInformationCallback={contactSubmit}
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
        />
      )}
    </>
  );
};

export default NavBarTransparent;
