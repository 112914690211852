import React from "react";
import "./styles.scss";

interface HrProps {
  hasHorizontalMargin?: boolean;
}

const Hr: React.FC<HrProps> = (props) => {
  const { hasHorizontalMargin = true } = props;
  return (
    <div
      id="separator-default"
      className={`${hasHorizontalMargin ? "horizontal-margin" : ""}`}
    />
  );
};

export default Hr;
