import React from "react";
import "./styles.scss";

interface ListItemProps {
  title: string;
  subtitle: string;
}

const ListItem: React.FC<ListItemProps> = ({ title, subtitle }) => {
  return (
    <div id="listitem-wrapper">
      <div className="circle-icon" />

      <div className="listitem-text-wrapper">
        <h5 className="title">{title}</h5>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

export default ListItem;
