import React from "react";
import "./styles.scss";
import Title from "../../Texts/Title";
import SpecsPill from "../../Pills/SpecsPill";
import ListSpec from "../../ListItem/ListSpec";

const boatSpecs = [
  {
    specTitle: "Length",
    subSpec: "12.04 m",
    mainSpec: "40'",
  },
  {
    specTitle: "Beam",
    subSpec: "3.48 m",
    mainSpec: "11'15",
  },
  {
    specTitle: "Draft",
    subSpec: "0.71 m",
    mainSpec: "2'4",
  },
  {
    specTitle: "Cruising Speed",
    subSpec: "approx.",
    mainSpec: "8 Knots",
  },
  {
    specTitle: "Year Built",
    subSpec: "",
    mainSpec: "2022",
  },
  {
    specTitle: "Builder",
    subSpec: "",
    mainSpec: "NX Boats",
  },
  {
    specTitle: "Model",
    subSpec: "",
    mainSpec: "400 HT Horizon",
  },
  {
    specTitle: "Class",
    subSpec: "(Power)",
    mainSpec: "Yacht",
  },
];

const SpecificationsCard: React.FC = () => {
  return (
    <div id="specifications-wrapper">
      <h3 className="disclaimer left">Specifications</h3>
      <div className="specs-highlight">
        <SpecsPill
          fontFamilyTitle="roboto"
          title={"Passengers"}
          spec={"2-12"}
        />
        <SpecsPill fontFamilyTitle="roboto" title={"Crew"} spec={"1-2"} />
        <SpecsPill fontFamilyTitle="roboto" title={"Cabins"} spec={"2"} />
      </div>
      {boatSpecs.map((spec, index) => (
        <React.Fragment key={index}>
          <ListSpec
            specTitle={spec.specTitle}
            subSpec={spec.subSpec}
            mainSpec={spec.mainSpec}
          />
          {index < boatSpecs.length - 1 && <div className="separator-list" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SpecificationsCard;
