import React from "react";
import "./styles.scss";

// interface PicePillProps {
//   price: string;
// }

interface SpecsPillProps {
  title: string;
  spec: string;
  fontFamilyTitle?: "futura" | "roboto" | "spectral" | "montserrat" | "SF";
}

const SpecsPill: React.FC<SpecsPillProps> = ({
  title,
  spec,
  fontFamilyTitle = "roboto",
}) => {
  return (
    <div id="specs-pill-wrapper">
      <div className="highlight-item">
        <span className={`highlight-text-top ${fontFamilyTitle}`}>{title}</span>
        <span className="highlight-text-bottom">{spec}</span>
      </div>
    </div>
  );
};

export default SpecsPill;
