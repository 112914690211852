import axios from "axios";
import { apiServices } from "../apiServices";

export const findSpecialCode = async (code_digits: string) => {
	try {
		const req = await axios({
			method: "post",
			url: `${apiServices()}/find-code`,
			data: { code_digits },
		});
		return { status: req.status, res: req.data };
	} catch (error) {
		return { status: 404, res: "special code not found" };
	}
 


};
