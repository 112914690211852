import React from "react";
import "./styles.scss";
import Hr from "../../Hr";

interface PicePillProps {
  price: string;
}

const PicePill: React.FC<PicePillProps> = ({ price }) => {
  return (
    <div id="price-pill-wrapper">
      {/* <div className="sup-texts">
        <p>Book this yacht</p>
        <p>Get ready to enjoy your day on the water</p>
      </div>
      <Hr hasHorizontalMargin={false} /> */}
      <div className="sub-texts">
        <p className="title">Starting at</p>
        <p className="price">${price}</p>
        {/* <p className="per-day">/3hrs</p> */}
      </div>
    </div>
  );
};

export default PicePill;
