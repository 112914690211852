export   const durationObject = [
	{
		label: "3hrs",
		value: "3hrs",
	},
	{
		label: "4hrs",
		value: "4hrs",
	},
	{
		label: "6hrs",
		value: "6hrs",
	},
	{
		label: "8hrs",
		value: "8hrs",
	},
];