import axios from "axios";
import { apiServices } from "../apiServices";

export type IListYachtInfo = {
  user_name: string;
  yacht_location: string;
  yacht_year: string;
  yacht_make: string;
  yacht_model: string;
  user_phone: string;
  user_email: string;
  via_link: string[] | undefined;
  to: string;
};

export const sendEmailListYachtInfo = async (data: IListYachtInfo) => {
  const req = await axios({
    method: "post",
    url: `${apiServices()}/send-list-info-yacht-email`,
    data,
  });

  return { status: req.status, res: req.data };
};
