import axios from "axios";
import { apiServices } from "../apiServices";

export type IContactUs = {
  user_name: string;
  user_email: string;
  user_phone: string;
  message: string;
  to: string;
};

export const sendEmailContact = async (data: IContactUs) => {
  const req = await axios({
    method: "post",
    url: `${apiServices()}/send-contact-email`,
    data,
  });

  return { status: req.status, res: req.data };
};
