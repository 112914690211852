import React, { ReactNode } from "react";
import "./styles.scss";
import Footer from "../../Footer";
import Boat from "../../../assets/images/image.png";
import NavBar from "../../NavBar";
import FeaturedCarousel from "../../Carousels/FeaturedCarousel";
import NavBarTransparent from "../../NavBar/NavBarTransparent";

interface LayoutDefaultProps {
  children: ReactNode;
  className?: string;
}

const imgCarousel = [
  {
    id: "1",
    img: Boat,
  },
  {
    id: "2",
    img: Boat,
  },
  {
    id: "3",
    img: Boat,
  },
  {
    id: "4",
    img: Boat,
  },
  {
    id: "5",
    img: Boat,
  },
];

const LayoutDefault: React.FC<LayoutDefaultProps> = (props) => {
  const { children, className } = props;

  return (
    <div id="layout-default" className={className}>
      {/* <NavBar formSubmitCallback={formSubmitCallback} /> */}
      <NavBarTransparent />
      {/* <FeaturedCarousel imagesCarousel={imgCarousel} /> */}
      <div className="main-container">{children}</div>
      <hr />
      <Footer />
    </div>
  );
};

export default LayoutDefault;
