import "./styles.scss";

interface SmokyContainerProps {
  image: string;
  alt: string;
}

const SmokyContainer: React.FC<SmokyContainerProps> = ({ image, alt }) => {
  return (
    <div id="smoky-container">
      <img src={image} alt={alt} />
    </div>
  );
};

export default SmokyContainer;
