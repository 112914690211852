export const formatDateTimeObj = (date: Date) => {
  const dateStart = new Date(date);

  const year = dateStart.getFullYear();
  const month = String(dateStart.getMonth() + 1).padStart(2, "0");
  const day = String(dateStart.getDate()).padStart(2, "0");

  let hour = dateStart.getHours();
  const min = String(dateStart.getMinutes()).padStart(2, "0");

  const amPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;

  return {
    date: `${year}-${month}-${day}`,
    time: `${hour}:${min} ${amPm}`,
  };
};
