import React from "react";
import "./styles.scss";
import Title from "../../Texts/Title";
import SubText from "../../Texts/SubText";

import checkIcon from "../../../assets/icons/bxs-check-circle.svg";

const DetailsCaption: React.FC = () => {
  return (
    <div id="disclaimer-wrapper">
      <div className="disclaimer-wrapper">
        <div className="disclaimer-inner-wrapper">
          <img src={checkIcon} alt="circle icon" />
          <div className="caption-text-wrapper">
            <h5>Book Your Adventure Today!</h5>
            <p>
              Don’t miss the chance to experience the exceptional NX 400 HT
              Horizon 2022. Contact us now to check availability and book your
              unforgettable adventure on the water.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCaption;
