import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import TitleParagraph from "../../components/Texts/TitleParagraph";
import { useNavigate } from "react-router-dom";
import FeaturedCarousel from "../../components/Carousels/FeaturedCarousel";
import { boats } from "../../mocks/boats";
import nxVideo from "../../assets/videos/video-destaque-Nx400ht.mp4";
import Button from "../../components/Buttons/Button";
import { initGA, logEvent, logPageView } from "../../utils/analytics";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    initGA();

    const captureUTMParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const allParams: Record<string, string> = {};

      urlParams.forEach((value, key) => {
        allParams[key] = value;
      });

      if (Object.keys(allParams).length > 0) {
        localStorage.setItem("urlParams", JSON.stringify(allParams));

        logEvent("URL Params", "Capture", JSON.stringify(allParams));
      }
    };

    const cleanURL = () => {
      if (window.location.search) {
        const url = new URL(window.location.href);
        url.search = "";
        window.history.replaceState({}, document.title, url.toString());
      }
    };

    captureUTMParams();
    cleanURL();
    logPageView();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInstantBook = () => {
    localStorage.setItem("scrollTarget", "#yacht-booking-form");
    navigate(`/yacht/${boats[0].routeName}`);
  };

  return (
    <div id="landing-page">
      <LayoutDefault className="landing-page">
        <FeaturedCarousel
          videoBanner
          //imagesCarousel={imgCarousel}
          infoText={{
            title: boats[0].texts.title,
            subTitle: boats[0].texts.subTitle,
            buttonText: "More Details",
            buttonClick: () => navigate(`/yacht/${boats[0].routeName}`),

            secondaryButtonText: "Instant Book",
            secondaryButtonClick: handleInstantBook,
          }}
        />
        <div className="boat-info-wrapper">
          <div className="title-paragraph-wrapper">
            <TitleParagraph
              title={"The Experience of a<br>Lifetime in Miami"}
              paragraph={`Whether you’re hosting a corporate event, enjoying a 
								family outing, or celebrating a special occasion, we’ve got you 
								covered with an unforgettable yacht experience in Miami.`}
              textAlign="center"
              paragraphColor="gray"
            />
          </div>
          <div className="instant-book-button-container">
            <Button
              buttonStyle="primary"
              children="More Details"
              onClick={() => navigate(`/yacht/${boats[0].routeName}`)}
            />
          </div>
          {/* <iframe
            className="video-player"
            src="https://www.youtube.com/embed/cVBMwjWEg_4?si=smC2FjuUaQZSUPdi"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe> */}
          <video className="video-player" autoPlay loop muted playsInline>
            <source src={nxVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div className="cards-row">
            {boats.map((card, index) => (
              <Card
                key={index}
                title={card.name}
                subTitle={card.specification}
                price={card.prices.card}
                vehicleImg={card.vehicleImg}
                showComingSoon={card.showComingSoon}
                onClick={() => navigate(`/yacht/${card.routeName}`)}
              />
            ))}
          </div> */}
        </div>
      </LayoutDefault>
    </div>
  );
};

export default LandingPage;
