import React from "react";
import "./styles.scss";

interface ListSpecProps {
  specTitle: string;
  subSpec?: string;
  mainSpec: string;
}

const ListSpec: React.FC<ListSpecProps> = ({
  specTitle,
  subSpec,
  mainSpec,
}) => {
  return (
    <div id="spec-list">
      <p className="spec-list-item spec-list-item-left">{specTitle}</p>
      <div className="spec-list-item-right">
        <p className="spec-sub-item">{subSpec}</p>
        <p className="spec-main-item">{mainSpec}</p>
      </div>
    </div>
  );
};

export default ListSpec;
