import { convertToISO8601Format } from "./dateIsoFormat";

export interface EventDetails {
  start_date: string;
  end_date: string | undefined;
  number_passenger: number;
  hours: string;
  start_hour: string;
  end_hour?: string; // Torna end_hour opcional
  user_name: string;
  user_phone: string;
  user_email: string;
  boat: string;
}

export const formatGoogleCalendarUrl = (event: EventDetails): string => {
  // Se end_hour não existir, use start_hour
  const endHour = event.end_hour ? event.end_hour : event.start_hour;

  const startDateTime = encodeURIComponent(
    convertToISO8601Format(event.start_date, event.start_hour)
  );
  
  // Se end_date não existir, use start_date
  const endDateTime = encodeURIComponent(
    convertToISO8601Format(event.end_date || event.start_date, endHour)
  );

  const title = encodeURIComponent("Yacht Booking");
  const details = encodeURIComponent(
    `Name: ${event.user_name}\n` +
      `Phone: ${event.user_phone}\n` +
      `Number of passengers: ${event.number_passenger}\n` +
      `Boat: ${event.boat}\n` +
      `Hours: ${event.hours}`
  );
  const add = encodeURIComponent("contact@yachtcharter.miami");

  const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDateTime}/${endDateTime}&details=${details}&add=${add}`;

  return googleCalendarUrl;
};