import React from "react";
import "./styles.scss";
import ListItem from "../ListItem";
import Title from "../Texts/Title";
import SubText from "../Texts/SubText";
import Hr from "../Hr";

import PriceCard from "../Cards/PriceCard";
import SpecificationsCard from "../Cards/SpecificationsCard";

// interface ListItemProps {
//   title: string;
//   subtitle: string;
// }

const Details: React.FC = () => {
  return (
    <div id="details-wrapper">
      <div className="main-text-wrapper">
        <h3 className="disclaimer">Yacht Details & Guest Accommodations</h3>
        <p className="description">
          The NX 400 HT Horizon 2022 combines sleek, modern lines with a bold
          aesthetic, delivering both style and substance. This motor yacht is
          designed to provide an unparalleled boating experience with its
          athletic style, luxurious finishes, and spacious comfort. The hardtop
          design offers superior protection from the elements, ensuring a
          comfortable journey in any weather.
        </p>
      </div>

      <Hr />
      <div className="list-detail">
        <div className="main-text-wrapper">
          <h3 className="disclaimer">Perfect for Every Occasion</h3>
          <p className="description">
            Whether you’re seeking a family adventure, a romantic getaway, or a
            unique venue for a corporate event, the NX 400 HT Horizon 2022 is
            the perfect choice. Its versatility and luxury make it ideal for:
          </p>
        </div>
        <div className="detail-list">
          <ListItem
            title={"Family Outings"}
            subtitle={
              "Create lasting memories with your loved ones on a spacious and luxurious yacht designed for comfort and fun."
            }
          />
          <ListItem
            title={"Romantic Escapes"}
            subtitle={
              "Escape to the sea and enjoy the beauty of the ocean with your significant other, perfect for romantic sunset cruises or secluded getaways."
            }
          />
          <ListItem
            title={"Corporate Events"}
            subtitle={
              "Host impressive events and meetings on board, offering a unique and luxurious experience for clients and colleagues."
            }
          />
          <ListItem
            title={"Celebrations"}
            subtitle={
              "Mark special occasions such as birthdays, anniversaries, or other milestones in style, with the stunning ocean as your backdrop."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
