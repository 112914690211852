import React from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface SlideCarouselProps {
  imagesCarousel: {
    id: string;
    img: string;
    type?: "video" | "photo";
  }[];
  slidesPerView?: number;
  onImageClick?: (img: { id: string; img: string }) => void;
}

const SlideCarousel: React.FC<SlideCarouselProps> = ({
  imagesCarousel,
  slidesPerView = 2,
  onImageClick,
}) => {
  const handleImageClick = (img: { id: string; img: string }) => {
    if (onImageClick) {
      onImageClick(img);
    }
  };

  return (
    <Swiper
      spaceBetween={1}
      slidesPerView={slidesPerView}
      navigation={true}
      className="slide-carousel-container"
      modules={[Navigation, Pagination]}
      breakpoints={{
        320: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 5,
        },
      }}
    >
      {imagesCarousel.map((img) => (
        <SwiperSlide
          key={img.id}
          className="swiper-slide"
          onClick={() => handleImageClick(img)}
        >
          {img.type && img.type === "video" ? (
            <video className="video-player" autoPlay loop muted playsInline>
              <source src={img.img} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={img.img} alt="yacht" />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlideCarousel;
